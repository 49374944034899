/* eslint-disable no-return-assign */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useQuery } from 'react-query';
import { parseCookies } from 'nookies';
import { useAuth } from 'utils/context/auth';
import { getCategories } from 'api/category';
import { getCountCart, getBudgetCompany } from 'api/cart';
import useCategoryStore from 'stores/category.store';
import { CONFIG_SITE, USER_ROLE } from 'constants/index';
import { WHITELABEL_KEYS } from 'global/constants/whitelabel-keys';
import { BUYING_OPTIONS } from 'global/constants/buying-options';
import { isValidBuyingType } from 'global/utils/global-config.utils';
import PropTypes from 'prop-types';
import { SearchIcon, ShoppingCartIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import ProfileMenu from 'components/ProfileMenu';
import NotificationMenu from 'components/NotificationMenu';
import { CategoryPopover } from 'components/Header/CategoryPopover';
import Button from 'components/Button';
import { clearEmptyObject } from 'global/utils/object.utils';
import HeaderCategoryCallpro from 'containers/HomepageCallpro/_components/HeaderCategory';
import ChatBusinessButton from 'containers/Chat/ChatBusiness/ChatBusinessButton';
import ChatRetailButton from 'containers/Chat/ChatRetail/ChatRetailButton';
import MobileMenu from '../MobileMenu';
import { isEnableChatB2B, isEnableChatRetail } from 'global/utils/chat.utils';
import { GTAG_EVENT_ACTION, gtagEvent } from 'utils/gtag.utils';

const Header = ({ isHomepage, isShowInfo, headerHeight, isLayoutDisabled = false }) => {
  const router = useRouter();
  const productName = router.query.q;
  const category = useCategoryStore();
  const cookies = parseCookies();
  const [searchValue, setSearchValue] = useState(() => productName);
  const { user } = useAuth();
  const { isLogedIn } = user.auth;
  const [isLogin, setIsLogin] = useState(false);
  const [isBuyerStaff, setIsBuyerStaff] = useState(false);

  const isCallpro = CONFIG_SITE?.WHITELABEL_KEY === WHITELABEL_KEYS.callpro;
  const isEnableOpenMarket = CONFIG_SITE?.ENABLE_OPEN_MARKET ?? false;

  const templateSubject = `Kendala%20${CONFIG_SITE?.CONTENT?.COMPANY?.DOMAIN}`;
  const templateMessage = `Halo%20${CONFIG_SITE?.CONTENT?.COMPANY?.BRAND},%20bisa%20bantu%20saya?%0D%0ANama%20:%20%0D%0AE-mail%20:%20%0D%0AMasalah%20saya%20:%20`;

  const { data, refetch } = useQuery('getCategories', getCategories, {
    refetchOnWindowFocus: true,
    refetchOnmount: false,
    refetchOnReconnect: true,
    retry: false,
    staleTime: 1000 * 60 * 60 * 24,
    enabled: false,
    onSuccess: (responseData) => {
      category?.setCategories(responseData?.data || []);
    },
  });

  const count = useQuery('getCountCart', () => getCountCart(), {
    enabled:
      !!cookies.isLogedIn && [USER_ROLE.CUSTOMER, USER_ROLE.BUYER_STAFF].includes(user.auth.role),
  });

  const { data: budget } = useQuery('getBudgetCompany', () => getBudgetCompany(), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!cookies.isLogedIn && user.auth.role === USER_ROLE.BUYER_STAFF,
  });

  const reportToGoogleAnalyticsSearch = (search_term) => {
    gtagEvent(GTAG_EVENT_ACTION.search, {
      search_term,
    });
  };

  const onSearchProduct = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const onSubmitSearchProduct = () => {
    reportToGoogleAnalyticsSearch(searchValue);
    router.replace({
      pathname: '/search',
      query: clearEmptyObject({
        q: searchValue,
      }),
    });
  };

  const onPressEnterSearchProduct = (event) => {
    if (event.type === 'keydown' && event.key === 'Enter') {
      event.preventDefault();
      reportToGoogleAnalyticsSearch(searchValue);
      router.replace({
        pathname: '/search',
        query: clearEmptyObject({
          q: searchValue,
        }),
      });
    }
  };

  const onAddToCart = () => {
    if (isLogin) {
      router.push('/cart');
    }
  };

  useEffect(() => {
    if (cookies.isLogedIn && setIsLogin(true)) {
      setIsBuyerStaff(user.auth.role === USER_ROLE.BUYER_STAFF);
    }
  }, [isLogedIn]);

  const isExpired = () => {
    const { date } = category;
    const categoriesStoreDate = new Date(date || new Date());
    const now = new Date();
    const expiryInSec = 60 * 60; // 1 jam
    const tempDate = new Date(categoriesStoreDate);
    tempDate.setHours(
      tempDate.getHours(),
      tempDate.getMinutes(),
      tempDate.getSeconds() + expiryInSec,
      0,
    );
    if (now > tempDate && category?.categories?.length > 0) {
      return true;
    }
    return false;
  };

  const detectCategory = () => {
    const { date } = category;

    if (!date) {
      return refetch();
    }

    if (isExpired()) {
      category?.reset();
      refetch();
    }
  };

  useEffect(() => {
    detectCategory();
  }, [category]);

  const showShoppingCart = useMemo(() => {
    if (CONFIG_SITE.DISABLE_CART) return false;

    return isLogedIn && [USER_ROLE.BUYER_STAFF, USER_ROLE.CUSTOMER].includes(user.auth.role);
  }, [isLogedIn, user]);

  const helps = useMemo(() => {
    const list = [];
    if (CONFIG_SITE?.CONTENT?.CONTACTS?.WHATSAPP?.LINK) {
      list.push({
        id: 'whatsapp',
        imgSrc: '/assets/icons/help-whatsapp-inactive.svg',
        imgSrcActive: '/assets/icons/help-whatsapp-active.svg',
        link: `${CONFIG_SITE?.CONTENT?.CONTACTS?.WHATSAPP?.LINK}?text=${templateMessage}`,
      });
    }
    if (CONFIG_SITE?.CONTENT?.CONTACTS?.TELEGRAM.LINK) {
      list.push({
        id: 'telegram',
        imgSrc: '/assets/icons/help-telegram-inactive.svg',
        imgSrcActive: '/assets/icons/help-telegram-active.svg',
        link: CONFIG_SITE?.CONTENT?.CONTACTS?.TELEGRAM.LINK,
      });
    }

    if (CONFIG_SITE?.CONTENT?.CONTACTS?.EMAIL?.SUPPORT) {
      list.push({
        id: 'email',
        imgSrc: '/assets/icons/help-email-inactive.svg',
        imgSrcActive: '/assets/icons/help-email-active.svg',
        link: `mailto:${CONFIG_SITE?.CONTENT?.CONTACTS?.EMAIL?.SUPPORT}?subject=${templateSubject}&body=${templateMessage}`,
      });
    }
    return list;
  }, []);

  const visibilityHelps = useMemo(() => {
    if ([WHITELABEL_KEYS.idfood].includes(CONFIG_SITE?.WHITELABEL_KEY)) {
      return false;
    }

    if ([WHITELABEL_KEYS.callpro].includes(CONFIG_SITE?.WHITELABEL_KEY)) {
      return false;
    }

    return true;
  }, []);

  const subMenu = useMemo(() => {
    if (CONFIG_SITE.WHITELABEL_KEY === WHITELABEL_KEYS.idfood) {
      return [
        {
          title: 'Belanja',
          path: '/',
          highlight: true,
          exactCheck: true,
        },
        // // Hide Produk unggulan dulu karena belom ada menu nya
        // {
        //   title: 'Produk Unggulan',
        //   path: '/',
        // },
        {
          title: 'Perusahaan',
          path: '/about',
        },
        // // Hide Informasi dulu karena belom ada menu nya
        // {
        //   title: 'Informasi',
        //   path: '/',
        // },
        {
          title: 'Alamat',
          path: '/contact-us',
        },
      ];
    }

    return [];
  }, []);

  const showNotification = useMemo(
    () =>
      isLogedIn &&
      [USER_ROLE.BUYER_STAFF, USER_ROLE.CUSTOMER, USER_ROLE.BUYER_MANAGER].includes(user.auth.role),
    [isLogedIn, user],
  );

  const checkIsSubmenuActive = useCallback(
    (subMenuItem) => {
      const { exactCheck = false } = subMenuItem;
      if (exactCheck) {
        return router.pathname === subMenuItem.path;
      }

      return router.pathname.startsWith(subMenuItem.path);
    },
    [router.pathname],
  );

  const getRegisterHrefLink = () => {
    if (isEnableOpenMarket || CONFIG_SITE?.WHITELABEL_KEY === WHITELABEL_KEYS.madaniMart) {
      return '/register';
    }

    if (isValidBuyingType(CONFIG_SITE, BUYING_OPTIONS.rent)) {
      return '/register/retail';
    }

    return '/register/retail/buyer';
  };

  return (
    <>
      <header className="fixed bg-white block top-0 left-0 right-0 z-40 shadow-md">
        <div className="w-full bg-grayScale02">
          {helps.length > 0 && visibilityHelps && (
            <div className="container mx-auto px-4 flex justify-end items-center gap-x-3 py-[16px]">
              <div className="text-red-500 text-[0.8rem] font-medium leading-3 hover:cursor-default">
                Pusat Bantuan
              </div>
              <div className="flex gap-x-2 items-center">
                {helps.map((item, index) => (
                  <a
                    key={index}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={item.link}
                    className="w-auto h-[16px] text-grayScale03 hover:text-theme-primary-main"
                  >
                    <img
                      src={item.imgSrc}
                      alt=""
                      className="h-full w-auto object-center object-cover"
                      onMouseOver={(e) => (e.currentTarget.src = item.imgSrcActive)}
                      onMouseOut={(e) => (e.currentTarget.src = item.imgSrc)}
                    />
                  </a>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="relative flex gap-3 flex-wrap items-center justify-start py-[12px] mx-auto h-full container px-4">
          <Link href={isLayoutDisabled ? '' : '/'}>
            <a
              className={`hidden md:block w-auto h-[35px] relative mr-6 ${
                isLayoutDisabled ? 'cursor-default' : 'cursor-pointer'
              }`}
            >
              <img src={CONFIG_SITE.LOGO_URL} alt="Logo" className="h-full w-auto" />
            </a>
          </Link>
          <Link href={isLayoutDisabled ? '' : '/'}>
            <a
              className={`flex md:hidden w-[40px] h-[40px] relative items-center ${
                isLayoutDisabled ? 'cursor-default' : 'cursor-pointer'
              }`}
            >
              <img
                src={CONFIG_SITE.LOGO_MOBILE_URL}
                alt="Logo"
                className="h-auto w-full object-cover object-center"
              />
            </a>
          </Link>
          <div
            className={`flex flex-1 gap-2 xl:gap-3 items-center ${
              isCallpro ? 'justify-between' : 'justify-around'
            }`}
          >
            {CONFIG_SITE?.WHITELABEL_KEY !== WHITELABEL_KEYS.idfood && (
              <div className="hidden md:block max-w-screen-md">
                {isCallpro ? (
                  <HeaderCategoryCallpro />
                ) : (
                  <CategoryPopover isHomepage={isHomepage} isLayoutDisabled={isLayoutDisabled} />
                )}
              </div>
            )}

            {!isCallpro && (
              <div className="flex-1 flex flex-col gap-1">
                <div className="flex">
                  <div className="relative flex items-stretch flex-grow focus-within:z-10">
                    <input
                      disabled={isLayoutDisabled}
                      type="text"
                      name="search"
                      id="search"
                      className={`block w-full border rounded-none rounded-l-lg pl-5 sm:text-sm border-gray-300 ${
                        isLayoutDisabled ? 'opacity-70 cursor-not-allowed' : ''
                      }`}
                      placeholder={`Cari Produk di ${CONFIG_SITE?.CONTENT?.BRAND}`}
                      value={searchValue}
                      onChange={onSearchProduct}
                      onKeyDown={onPressEnterSearchProduct}
                    />
                  </div>
                  <button
                    type="button"
                    disabled={isLayoutDisabled}
                    className={`-ml-px relative inline-flex items-center space-x-2 px-4 py-1.5 border border-gray-300 text-sm font-medium rounded-r-lg text-gray-700 bg-gray-50 focus:outline-none ${
                      isLayoutDisabled ? 'cursor-not-allowed opacity-50' : 'hover:bg-gray-100'
                    }`}
                    onKeyDown={onSubmitSearchProduct}
                    onClick={onSubmitSearchProduct}
                  >
                    <SearchIcon width={24} height={24} color="#4F4F4F" />
                  </button>
                </div>
              </div>
            )}

            {CONFIG_SITE?.WHITELABEL_KEY === WHITELABEL_KEYS.idfood && (
              <div className="hidden lg:block">
                <CategoryPopover isHomepage={isHomepage} />
              </div>
            )}
            <div className="flex items-center justify-end gap-2 pl-2">
              {showShoppingCart && (
                <button type="button" className="relative" onClick={onAddToCart}>
                  <ShoppingCartIcon width={24} height={24} color="#4F4F4F" />
                  {isLogin && count?.data?.data > 0 && (
                    <span className="absolute top-[-12px] -right-[10px] flex justify-center items-center h-[18px] w-[18px] bg-theme-primary-main rounded-[20px] text-white text-[10px]">
                      {count?.data?.data || 0}
                    </span>
                  )}
                </button>
              )}
              {isEnableChatB2B(CONFIG_SITE) && <ChatBusinessButton />}
              {isEnableChatRetail(CONFIG_SITE) && <ChatRetailButton />}
              {showNotification && (
                <div className="">
                  <NotificationMenu headerHeight={headerHeight} />
                </div>
              )}
              <span className="hidden lg:block w-[2px] bg-lightGray h-10 mx-3" />
              <div
                className={clsx(
                  'flex flex-row text-sm justify-end',
                  isLogin && 'w-[200px]',
                  'hidden lg:block',
                )}
              >
                {isLogin ? (
                  <ProfileMenu
                    isHomepage={isHomepage}
                    remainingBalance={budget?.data?.remainingBalance}
                    isShowInfo={isShowInfo}
                  />
                ) : (
                  <div className="flex gap-2">
                    {(CONFIG_SITE?.IS_SHOW?.REGISTER_RETAIL || isEnableOpenMarket) && (
                      <Link href={getRegisterHrefLink()}>
                        <Button
                          onClick={() => {}}
                          variant="outlined"
                          size="large"
                          className="gtm-register-button"
                          disabled={isLayoutDisabled}
                        >
                          Daftar
                        </Button>
                      </Link>
                    )}

                    {CONFIG_SITE.SELF_REGISTER_B2B && (
                      <Button
                        onClick={() => {
                          router.push('/register/business');
                        }}
                        variant="outlined"
                        size="large"
                      >
                        Daftar
                      </Button>
                    )}

                    <Button
                      onClick={() => {
                        router.push('/login');
                      }}
                      variant="primary"
                      size="large"
                      disabled={isLayoutDisabled}
                    >
                      Masuk
                    </Button>
                  </div>
                )}
              </div>

              <div className="block lg:hidden">
                <MobileMenu
                  isHomepage={isHomepage}
                  remainingBalance={budget?.data?.remainingBalance}
                  isShowInfo={isShowInfo}
                  subMenu={subMenu}
                  isLayoutDisabled={isLayoutDisabled}
                />
              </div>
            </div>
          </div>
        </div>
        {CONFIG_SITE?.WHITELABEL_KEY === WHITELABEL_KEYS.idfood && (
          <div className="container mx-auto hidden lg:block">
            <ul className="flex pt-1 pb-3 text-sm pl-3 ml-0 lg:ml-[180px]">
              {subMenu.map((item) => {
                const isActive = checkIsSubmenuActive(item);
                return (
                  <li key={item.title}>
                    <Link href={item.path}>
                      <a
                        // href={item.path}
                        // className={`${item.highlight ? ' bg-[#68C400] rounded-full' : ''} px-3 py-1`}
                        className={`${isActive ? ' bg-[#68C400] rounded-full' : ''} px-3 py-1`}
                      >
                        {item.title}
                      </a>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </header>
    </>
  );
};

Header.propTypes = {
  isShowInfo: PropTypes.bool,
  isHomepage: PropTypes.bool,
  headerHeight: PropTypes.number,
};

Header.defaultProps = {
  isHomepage: false,
  isShowInfo: true,
  headerHeight: 0,
};

export default Header;
